import firebase, { functions } from '../../firebase';
import useTenant from './useTenant';
import { auditData } from '../../shared/models/user';

const db = firebase.firestore();
const accessLogsRef = db.collection('accessLogs');
const getIPAddress = functions.httpsCallable('getIPAddress');

export default function useAccessLog() {
  const tenant = useTenant();

  const createAccessLog = async (user, location) => {
    const { data: ipAddress } = await getIPAddress();
    // @ts-ignore
    const fingerprint = await window.fpPromise.then((fp) => fp.get()).then((_) => _.visitorId);
    const createdBy = user
      ? { ...auditData(user), ipAddress, fingerprint, role: user.role || 'user' }
      : { ipAddress, fingerprint, role: 'unauth' };
    const qrUrlIdMatch = location.search.match(/qrUrlId=([a-zA-Z0-9-]+)/);
    const qrUrlId = qrUrlIdMatch ? qrUrlIdMatch[1] : null;
    const referrerMatch = location.search.match(/referrerKey=([a-z0-9-]+)/);
    const referrerKey = referrerMatch ? referrerMatch[1] : null;
    const before = document.referrer;
    const ref = await accessLogsRef.add({
      path: `${location.pathname}${location.search || ''}`,
      ...(tenant && { tenantId: tenant.id }),
      before,
      qrUrlId,
      referrerKey,
      documentTitle: document.title,
      createdBy,
      createdAt: new Date(),
    });
    return ref.id;
  };

  const updateAccessLog = async (accessLogId) => {
    await accessLogsRef.doc(accessLogId).update({ lastVisitedAt: new Date() });
  };

  return { createAccessLog, updateAccessLog };
}
