import { addDays, } from 'date-fns';
import { useLocalStorage, } from 'react-use';

import useTenant from './useTenant';

export default function useAvailableReferrerKey () {
  const tenant = useTenant();
  const [referrerKeyInStorage] = useLocalStorage(['referrerKey', tenant.id].join('__'));
  const [referrerKeySavedAtString] = useLocalStorage(['referrerKeySavedAt', tenant.id].join('__'));
  const referrerKeySavedAt = referrerKeySavedAtString && new Date(JSON.parse(referrerKeySavedAtString));
  const [referrers] = useLocalStorage(['referrers', tenant.id].join('__'), []);
  const [referrer] = referrers.filter(_ => new Date(_.referrerKeySavedAt) > addDays(new Date(), -30)).reverse();

  if (referrerKeyInStorage) {
    // 旧リファラがある場合はそれを返す
    return referrerKeySavedAt > addDays(new Date(), -30) ? referrerKeyInStorage : null;
  } else {
    return referrer?.referrerKey || null;
  }
};
