import React, { Fragment, useState, useEffect, useMemo, } from 'react';
import { get, isEmpty, pick } from 'lodash';
import { TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import qs from 'qs';
import { useToggle, useInterval } from 'react-use';
import retry from 'async-retry';
import dedent from 'dedent';

import firebase, { functions } from '../../firebase';
import { errorMessages as userErrorMessages } from '../../shared/models/user';
import Page from './Page';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import SignInForm from '../forms/SignInForm';
import SignUpForm from '../forms/SignUpForm';
import HeaderNav from '../HeaderNav';
import PleaseConfirmEmail from '../PleaseConfirmEmail';
import TenantLink from '../TenantLink';
import useAccessLog from '../hooks/useAccesslog';
import useLocale from '../hooks/useLocale';

const auth = firebase.auth();
const db = firebase.firestore();
const usersRef = db.collection('users');
const createUserAndSendEmailVerification = functions.httpsCallable('createUserAndSendEmailVerification');
const createSubUser = functions.httpsCallable('createSubUser');

export default function UserPageHOC(WrappedComponent) {
  return Page(function UserPage(props) {
    const { match, match: { params: { tenantPath } }, location, setLang, lang, locale, translate, } = props;
    const [firebaseUser, setFirebaseUser] = useState();
    const [showsLoginForm, toggleLoginForm] = useToggle();
    const { key: theDayKey, subUserInvitationToken } = qs.parse(window.location.search.slice(1));
    const [tab, setTab] = useState(([theDayKey, subUserInvitationToken].some((_) => _ != null) || WrappedComponent.defaultSignUp) ? 'signUp' : 'signIn');
    const myAccount = useDocumentSubscription(firebaseUser && usersRef.doc(firebaseUser.uid), [firebaseUser]);
    const mainAccount = useDocumentSubscription(!isEmpty(myAccount?.mainUserId) && usersRef.doc(myAccount.mainUserId), [
      myAccount,
    ]);
    const user = isEmpty(myAccount?.mainUserId) ? myAccount : mainAccount;
    const isInvitingSubUser = subUserInvitationToken != null;

    useEffect(() => {
      auth.onAuthStateChanged((firebaseUser) => {
        setFirebaseUser(firebaseUser);
        if (firebaseUser) {
          toggleLoginForm(false);
        } else {
          auth.signOut();
          toggleLoginForm(!WrappedComponent.preview || !!WrappedComponent.initiallyOpenLogin);
        }
      });
    }, []);
    useEffect(() => {
      user?.lang && setLang(user.lang);
    }, [user]);

    const updateUser = async () => {
      if (!firebaseUser.displayName) return;

      const doc = await usersRef.doc(firebaseUser.uid).get();
      if (!doc.exists) return;

      await doc.ref.set(pick(firebaseUser, ['uid', 'email', 'displayName']), { merge: true });
    };

    useEffect(() => {
      if (firebaseUser) {
        updateUser();
      }
    }, [firebaseUser]);

    const [accessLogId, setAccessLogId] = useState();
    const { createAccessLog, updateAccessLog } = useAccessLog();
    useEffect(() => {
      new Promise((resolve) => auth.onAuthStateChanged(firebaseUser => resolve(!!firebaseUser)))
        .then(isLoggedIn => ((isLoggedIn && user) || !isLoggedIn) && createAccessLog(user, location))
        .then(setAccessLogId);
    }, [user, location]);
    useInterval(() => {
      accessLogId && updateAccessLog(accessLogId);
    }, 10 * 1000);

    const onSubmitSignInForm = async (values) => {
      const { email, password } = values;
      try {
        await auth.signInWithEmailAndPassword(email, password);
      } catch (e) {
        console.error(e);
        const message = userErrorMessages[e.code] || 'ログインに失敗しました';
        toast.error(translate(message), { autoClose: false });
      }
    };

    const onSubmitSignUpForm = async (values) => {
      const { email, password, displayName, phone, postalCode, prefecture, city, } = values;
      try {
        if (isInvitingSubUser) {
          await createSubUser({ subUserInvitationToken, email, displayName, phone, password });
          await retry(
            async () => {
              await auth.signInWithEmailAndPassword(email, password);
            },
            { retries: 3 }
          );
          toast.success(translate('サブアカウントを作成しました'));
          window.alert(translate(dedent`
            サブアカウントを作成しました。
            注文履歴から、製品の組立て方、使い方、不具合の問合せができます。
          `));
        } else {
          const userValues = {
            displayName,
            email,
            phone,
            postalCode,
            prefecture,
            city,
            tenantIds: [match.params.tenantPath].filter(_ => _),
            lang,
          };
          await createUserAndSendEmailVerification({
            userValues,
            password,
            pathname: encodeURIComponent(window.location.pathname),
            search: encodeURIComponent(window.location.search),
            localeId: locale?.id,
          });
          try {
            await auth.signInWithEmailAndPassword(email, password);
          } catch (e) {
            // NOTE: 最悪ログインは失敗しても問題ないのでスルー
            console.error(e);
          }
          toast.success(translate('ご登録のメールアドレスに確認メールをお送りしました'));
          window.scrollTo(0, 0);
        }
      } catch (e) {
        console.error(e);
        const code = get(e, 'details.code') || e.code;
        const message = userErrorMessages[code] || '新規登録に失敗しました';
        toast.error(translate(message), { autoClose: false });
      }
    };

    const contents = (
      <div className="user-page h-100 d-flex flex-column mb-5">
        <HeaderNav {...props} {...{ user, myAccount, mainAccount, translate, }} />
        <div className="flex-grow-1">
          {(WrappedComponent.preview || firebaseUser === null || (firebaseUser !== null && user != null)) && (
            <WrappedComponent {...props} {...{ firebaseUser, user, myAccount, mainAccount, toggleLoginForm, }} />
          )}
        </div>
      </div>
    );

    const loginContent = (
      <Fragment>
        <Nav tabs>
          {!isInvitingSubUser && (
            <NavItem>
              <NavLink
                className={classnames('cursor-pointer', { active: tab === 'signIn' })}
                onClick={(_) => setTab('signIn')}
              >
                ログイン
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              className={classnames('cursor-pointer', { active: tab === 'signUp' })}
              onClick={(_) => setTab('signUp')}
            >
              新規登録
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={tab}>
          <TabPane tabId="signIn" className="pt-3">
            <SignInForm onSubmit={onSubmitSignInForm} translate={translate} />
          </TabPane>
          <TabPane tabId="signUp" className="pt-3">
            <SignUpForm onSubmit={onSubmitSignUpForm} isForSubUser={isInvitingSubUser} translate={translate} abroad={locale?.id.endsWith('en')} full={WrappedComponent.signsUpWithFullFields} />
          </TabPane>
        </TabContent>
      </Fragment>
    );
    const loginScreenAlerts = [
      match.path.includes('contacts') && (
        <div className="alert alert-info">
          <div>
            アカウントをお持ちでない方は<TenantLink to="/inquiries/new">こちら</TenantLink>
            からお問合せください。
          </div>
          <div>アカウントをお持ちの方はログインしてください。</div>
        </div>
      ),
      match.path.includes('checkInDirect') && (
        <div className="alert alert-info">
          <div>チェックインするにはログインが必要です</div>
        </div>
      ),
    ].filter(_ => _);

    return (
      <div className="user-page h-100">
        {WrappedComponent.preview ? (
          <Fragment>
            {contents}
            {showsLoginForm && translate(
              <Modal isOpen>
                <ModalHeader>ログイン</ModalHeader>
                <ModalBody>{loginContent}</ModalBody>
              </Modal>
            )}
          </Fragment>
        ) : firebaseUser ? (
          firebaseUser.emailVerified || ['staff', 'admin'].includes((user || {}).role) ? (
            user != null && contents
          ) : translate(
            <div className="container p-5">
              <PleaseConfirmEmail firebaseUser={firebaseUser} />
            </div>
          )
        ) : (
          showsLoginForm && (
            <div className="user-page h-100 d-flex flex-column mb-5">
              <HeaderNav {...props} {...{ user, translate, }} />
              <div className="row mt-5 mx-0">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="p-2">
                    {loginScreenAlerts}
                    {loginContent}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    );
  });
}
