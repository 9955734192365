import { useHistory } from 'react-router';
import UserPage from '../hocs/UserPage';

export default UserPage(function Mypage() {
  const history = useHistory();

  // TODO: マイページが整うまでは注文履歴にリダイレクトする
  history.replace('/mypage/orders');
  return null;
});
