import React, { Fragment } from 'react';
import { isEmpty, orderBy, sortBy, pick, omit, get, keyBy } from 'lodash';
import { format as formatDate } from 'date-fns';
import { Button } from 'reactstrap';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import dedent from 'dedent';

import { canUpdateMethodInquiry, canApproveMethodInquiry } from '../../shared/abilities';
import { answerDisplayValue, } from '../../util';
import firebase, { functions } from '../../firebase';
import {
  statuses,
  fields as _fields,
  basicFields,
  itemFields,
  shipmentFields,
  commentFields,
  activityTypes,
  replyEmailBody,
  shipmentMailBody,
  shippingDestination,
  supportContentsFields,
  emailFields,
} from '../../shared/models/methodInquiry';
import { paymentMethod, destinationFields } from '../../shared/models/order';
import { fieldDisplayValue } from '../../shared/util';
import { supportMeans } from '../../shared/config';
import AdminPage from '../hocs/AdminPage';
import { InquiryShipmentButton } from '../InquiryShipmentButton';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCreateActivity from '../hooks/useCreateActivity';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import InquiryItemsFormModal from '../modals/InquiryItemsFormModal';
import ModelFormModal from '../modals/ModelFormModal';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import { InquiryUpdatePicButton } from '../InquiryUpdatePicButton';
import InquirySubAccountAlert from '../InquirySubAccountAlert';
import InquiryReinquiryAlert from '../InquiryReinquiryAlert';
import ModalButton from '../ModalButton';
import Activity from '../Activity';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const methodInquiriesRef = db.collection('methodInquiries');
const productTypesRef = db.collection('productTypes');
const productsRef = db.collection('products');
const activitiesRef = db.collection('activities');
const ordersRef = db.collection('orders');
const surveysRef = db.collection('surveys');
const usersRef = db.collection('users');
const questionsRef = db.collection('questions');
const storageRef = firebase.storage().ref();
const sendMethodInquiryEmail = functions.httpsCallable('sendMethodInquiryEmail');
const methodInquiryShipment = functions.httpsCallable('methodInquiryShipment');
const { entries, keys } = Object;

export default AdminPage(function AdminMethodInquiry(props) {
  const {
    user,
    match: {
      params: { methodInquiryId },
    },
  } = props;
  const createActivity = useCreateActivity();
  const productTypes = useCollectionSubscriptionInTenant(productTypesRef.orderBy('index'));
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('createdAt'));
  const sortedProducts = sortBy(products, ({ isHidden }) => (isHidden ? 1 : 0));
  const selectableProducts = sortedProducts.filter(_ => !_.isBody).map((product) => ({
    ...product,
    ...(product.isHidden && { label: `[非表示] ${product.name}` }),
  }));
  const productsById = keyBy(products, 'id');
  const fields = { ...omit(_fields({ productTypes, products }), ['destinationType']), };
  const methodInquiry = useDocumentSubscription(methodInquiriesRef.doc(methodInquiryId), [methodInquiryId]);
  const childMethodInquiries = useCollectionSubscriptionInTenant(methodInquiriesRef.where('sourceMethodInquiryId', '==', methodInquiryId), [methodInquiryId]);
  const commentsRef = methodInquiriesRef.doc(methodInquiryId).collection('methodInquiryComments');
  const comments = useCollectionSubscription(commentsRef.orderBy('createdAt', 'desc'), [methodInquiryId]);
  const activities = useCollectionSubscriptionInTenant(
    activitiesRef.where('payload.methodInquiryId', '==', methodInquiryId),
    [methodInquiryId]
  );
  const shippable =
    methodInquiry &&
    !methodInquiry.shipmentRequested &&
    methodInquiry.items?.every((_) => _.quantity <= get(productsById, [_.productId, 'normalInventory'], 0));
  const shipmentOrderId = methodInquiry?.orderId;
  const shipmentOrder = useDocumentSubscription(shipmentOrderId && db.collection('orders').doc(shipmentOrderId), [shipmentOrderId]);
  const sourceOrderId = methodInquiry?.sourceOrder?.id;
  const sourceOrder = useDocumentSubscription(sourceOrderId && ordersRef.doc(sourceOrderId), [sourceOrderId]);
  const { label: statusLabel, color } = (methodInquiry && statuses[methodInquiry.status]) || {};
  const thWidth = 220;
  const surveys = useCollectionSubscriptionInTenant(surveysRef);
  const surveysById = keyBy(surveys, 'id');
  const isReinquiry = methodInquiry?.sourceTroubleInquiryId != null;
  const methodInquirySurvey = surveysById[productsById[methodInquiry?.productId]?.[isReinquiry ? 'methodReinquirySurveyId' : 'methodInquirySurveyId']];
  const relatedQuestions = useDocumentsFetch((methodInquirySurvey?.questionRows?.map(_ => _.questionId) || []).map(_ => questionsRef.doc(_)), [methodInquirySurvey]);
  const onClickStart = async () => {
    if (!window.confirm('本当に対応開始しますか？')) return;

    await methodInquiry.ref.update({ status: 'supporting', respondedBy: user });
    await createActivity('startSupportOfMethodInquiry', user, { methodInquiryId, uid: methodInquiry.createdBy.uid });
  };
  const onClickUnfinish = async () => {
    if (!window.confirm('本当に対応中に戻しますか？')) return;

    const status =
      methodInquiry.hasReimbursement && !methodInquiry.reimbursementRequest?.requestedAt ? 'awaiting' : 'supporting';
    await methodInquiry.ref.update({ status });
    await createActivity('unfinishSupportOfMethodInquiry', user, { methodInquiryId, uid: methodInquiry.createdBy.uid });
  };
  const validateOnDone = async () => {
    if (!window.confirm('本当に対応完了しますか？')) return false;
    return true;
  };
  const onDoneFinished = async (values) => {
    const note = `${values.supportSummary} (${values.supportMeans.map((_) => supportMeans[_]).join(',')})`;
    await methodInquiry.ref.update({ status: 'supported' });
    await createActivity('finishSupportOfMethodInquiry', user, { methodInquiryId, note, uid: methodInquiry.createdBy.uid });
  };
  const onSubmitUpdatePic = async () => {
    await createActivity('updatePicOfMethodInquiry', user, { methodInquiryId, uid: methodInquiry.createdBy.uid });
  };
  const onSubmitEmail = async (values, { onClickClose, }) => {
    try {
      const attachedFiles = await Promise.all(
        Array.from(values.files || []).map(async (file) => {
          const storagePath = `methodInquiries/${methodInquiryId}/attachedFiles/${new Date().toISOString()}/${file.name}`;
          const fileRef = storageRef.child(storagePath);
          await fileRef.put(file, { contentType: file.type });
          return {
            ...pick(file, ['name', 'type']),
            storagePath,
            url: await fileRef.getDownloadURL(),
          };
        })
      );
      await sendMethodInquiryEmail({ methodInquiryId: methodInquiry.id, values, });
      await methodInquiry.ref.update({
        messages: firebase.firestore.FieldValue.arrayUnion({ ...omit(values, 'files'), attachedFiles, sentAt: new Date(), sentBy: pick(user, ['email', 'uid', 'displayName']), }),
      });
      const body = replyEmailBody(methodInquiry, values.body, products, productTypes, relatedQuestions, window.location.origin)
      await createActivity('sendMethodInquiryEmail', user, { methodInquiryId, uid: methodInquiry.createdBy.uid, body, messageBody: values.body, attachedFiles });
      toast.success('送信しました');
      onClickClose();
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const onShipmentFinished = async () => {
    await createActivity('createOrderOfMethodInquiry', user, {
      methodInquiryId,
      uid: methodInquiry.createdBy?.uid || null,
    });
  };
  const onEdited = async ({ items }) => {
    await createActivity('editMethodInquiry', user, {
      methodInquiryId,
      uid: methodInquiry.createdBy?.uid || null,
    });
  };
  const onEditedDestination = async () => {
    await createActivity('editDestinationOfMethodInquiry', user, {
      methodInquiryId,
      uid: methodInquiry.createdBy?.uid || null,
    });
  };
  const mailConfirmBody = ({ values }) => {
    const body = replyEmailBody(methodInquiry, values.body, products, productTypes, relatedQuestions, window.location.origin)
    return (
      <div>
        <div>以下のようなメールを送ります。よろしいですか？</div>
        <div className="card p-3 mt-3" style={{ whiteSpace: 'pre-line' }}>
          {body}
        </div>
        <div className="mt-4">
          <h5>メッセージ内容</h5>
          <div className="card p-3" style={{ whiteSpace: 'pre-line', }}>
            {values.body}
          </div>
        </div>
      </div>
    );
  };

  return (
    methodInquiry != null && (
      <div className='admin-method-inquiry container-fluid'>
        <div className='p-4 bg-white my-4'>
          <div className='d-flex justify-content-center mb-3'>
            <h4>乗り方・使い方問合せ詳細</h4>
          </div>
          <InquirySubAccountAlert inquiry={methodInquiry} />
          <InquiryReinquiryAlert inquiry={methodInquiry} />
          <div className='d-flex flex-wrap text-nowrap justify-content-end mb-3 gap-2'>
            {methodInquiry.status !== 'supported' && (
              <InquiryUpdatePicButton inquiry={methodInquiry} onSubmit={onSubmitUpdatePic} />
            )}
            <ModalButton Modal={ModelFormModal} modalProps={{ title: 'メール送信', fields: emailFields(), submitLabel: '送信する', onSubmit: onSubmitEmail, confirms: true, confirmBody: mailConfirmBody, }}>
              <span className="fas fa-paper-plane mr-1" /> メール送信
            </ModalButton>
            {['supporting', 'awaiting'].includes(methodInquiry.status) && (
              <Fragment>
                <EditButton
                  label="発送先変更"
                  icon={null}
                  itemRef={methodInquiry.ref}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: '発送先を変更する',
                    fields: destinationFields,
                    submitLabel: '変更する',
                    values: shippingDestination(methodInquiry),
                  }}
                  disabled={!canUpdateMethodInquiry(user)}
                  onFinish={onEditedDestination}
                />
                <EditButton
                  itemRef={methodInquiry.ref}
                  FormModal={InquiryItemsFormModal}
                  disabled={!canUpdateMethodInquiry(user)}
                  formProps={{ products: selectableProducts, itemFields }}
                  onFinish={onEdited}
                />
                <InquiryShipmentButton
                  inquiryRef={methodInquiry.ref}
                  inquiry={methodInquiry}
                  user={user}
                  disabled={!shippable}
                  products={products}
                  productTypes={productTypes}
                  shipmentFields={shipmentFields}
                  shipmentMailBody={shipmentMailBody}
                  onFinish={onShipmentFinished}
                  inquiryShipment={methodInquiryShipment}
                  shipmentOrder={shipmentOrder}
                />
                <EditButton
                  label='対応完了'
                  icon={null}
                  color='primary'
                  itemRef={methodInquiry.ref}
                  FormModal={ModelFormModal}
                  formProps={{
                    title: 'サポート内容を登録する',
                    fields: supportContentsFields({ surveys }),
                    submitLabel: '登録する',
                  }}
                  validateOnSubmit={validateOnDone}
                  onFinish={onDoneFinished}
                />
              </Fragment>
            )}
            {methodInquiry.status === 'initial' && (
              <Button color='primary' onClick={onClickStart}>
                対応開始
              </Button>
            )}
            {methodInquiry.status === 'supported' && (
              <Button color='primary' outline onClick={onClickUnfinish}>
                対応中に戻す
              </Button>
            )}
          </div>
          <div className="d-sm-flex justify-content-around mt-5 gap-4">
            <div className="flex-fill">
              <table className='table table-bordered'>
                <tbody className='thead-light'>
                  <tr>
                    <th style={{ width: thWidth }}>ID</th>
                    <td>{methodInquiry.id}</td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>状態</th>
                    <td>
                      <span className={`badge badge-${color || 'secondary'}`}>{statusLabel}</span>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>お問合せ日時</th>
                    <td>{formatDate(methodInquiry.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>元受注ID</th>
                    <td>{methodInquiry.sourceOrder?.id}</td>
                  </tr>
                  <tr>
                    <th style={{ width: thWidth }}>元受注日時</th>
                    <td>
                      {methodInquiry.sourceOrder != null &&
                        formatDate(methodInquiry.sourceOrder.createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                    </td>
                  </tr>
                  {entries(basicFields({ productTypes, products })).map(([fieldName, fieldSetting]) => {
                    const displayValue = fieldDisplayValue(methodInquiry[fieldName], fieldSetting);
                    return (
                      <tr key={fieldName}>
                        <th style={{ width: thWidth }}>{fieldSetting.label}</th>
                        <td style={{ whiteSpace: 'pre-line' }}>
                          {displayValue}
                        </td>
                      </tr>
                    );
                  })}
                  {relatedQuestions.map((question) => {
                    const { id, type, name } = question;
                    const answer = methodInquiry.answers[id];
                    return (
                      <tr key={id}>
                        <th key={id} style={{ width: thWidth }}>
                          {name}
                        </th>
                        <td>{answerDisplayValue(answer)}</td>
                      </tr>
                    );
                  })}
                  {entries(omit({ ...fields }, keys(basicFields()))).map(([fieldName, fieldSetting]) => {
                    const label = keys(destinationFields()).includes(fieldName)
                      ? `発送先 - ${fieldSetting.label}`
                      : fieldSetting.label;
                    const displayValue = fieldDisplayValue(methodInquiry[fieldName], fieldSetting);
                    return (
                      <tr key={fieldName}>
                        <th style={{ width: thWidth }}>{label}</th>
                        <td style={{ whiteSpace: 'pre-line' }}>
                          {fieldName === 'name' ? (
                            <TenantLink to={`/admin/users/${methodInquiry.createdBy.uid}`}>{displayValue}</TenantLink>
                          ) : (
                            displayValue
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-5 mt-sm-0" style={{ minWidth: '40vw' }}>
              {
                !isEmpty(childMethodInquiries) && (
                  <div>
                    <h5>再問合せ</h5>
                    <div className="overflow-auto" style={{ maxHeight: 360, }}>
                      {
                        orderBy(childMethodInquiries, _ => _.createdAt.toDate(), 'desc').map((childMethodInquiry) => {
                          const { id, createdAt, } = childMethodInquiry;
                          return (
                            <div key={id} className="d-flex justify-content-between">
                              <TenantLink to={`/admin/methodInquiries/${id}`}>{id}</TenantLink>
                              <div>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</div>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                )
              }
              <hr className='my-5' />
              <div>
                <div className='d-flex justify-content-end mb-3'>
                  <AddInTenantButton
                    label='新規コメント'
                    itemRef={commentsRef.doc()}
                    processValues={(_) => ({ ..._, createdBy: pick(user, ['uid', 'email', 'displayName']) })}
                    FormModal={ModelFormModal}
                    formProps={{ title: '新規コメント', fields: commentFields }}
                  />
                </div>
                <div className="overflow-auto" style={{ maxHeight: 360, }}>
                  {comments.length > 0 ? (
                    <div>
                      {comments.map(({ id, body, createdAt, createdBy }) => {
                        return (
                          <div key={id} className='card p-3 mb-3'>
                            <div className='small text-muted mb-1'>
                              {createdBy.displayName} {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}
                            </div>
                            <div style={{ whiteSpace: 'pre-line' }}>{body}</div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div>コメントはまだありません</div>
                  )}
                </div>
              </div>
              <hr className='my-5' />
              <div>
                <h5>アクティビティ</h5>
                <div className="overflow-auto" style={{ maxHeight: 360, }}>
                  {activities.length > 0 ? (
                    <div className='d-flex flex-column gap-1'>
                      {orderBy(activities, (_) => _.createdAt.toDate(), 'desc').map((_) => (
                        <Activity activity={_} activityTypes={activityTypes} />
                      ))}
                    </div>
                  ) : (
                    <div>アクティビティはまだありません</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
});
