const { isEmpty } = require('lodash');
const dedent = require('dedent');

const { prefectures, langs, } = require('../config');

const { entries } = Object;

module.exports = {
  fields: ({ places = [], staffs = [], user = {} } = {}) => {
    return {
      name: {
        label: 'イベント名',
        type: 'string',
        validations: { required:  v => !isEmpty(v), }
      },
      lang: {
        label: '言語',
        type: 'select',
        options: entries(langs).map(([k, v]) => ({ value: k, label: v.label })),
        validations: { required:  v => !isEmpty(v), },
        initialValue: 'ja',
      },
      placeId: {
        label: '開催場所',
        type: 'select',
        options: places.map(({ id, name, prefecture }) => ({ label: `${name} (${prefectures[prefecture]})`, value: id })),
        validations: { required:  v => !isEmpty(v), }
      },
      picId: {
        label: '担当者',
        type: 'select',
        options: staffs.map(({ id, displayName, email }) => ({ label: `${displayName} (${email})`, value: id })),
        initialValue: user.uid,
        validations: { required:  v => !isEmpty(v), },
      },
      lectureFrameExpireHours: {
        label: '申込期限(終了n時間前)',
        type: 'integer',
        initialValue: 0,
        validations: {
          required: v => v != null,
          greaterThanOrEqualTo0: v => v == null || v >= 0,
        },
      },
      description: {
        label: '説明文(HTML)',
        type: 'richText',
        rows: 10,
        initialValue: '',
      },
      confirmationMailHeaderMessage: {
        label: '申込時メール冒頭部の差込文',
        type: 'text',
        rows: 6,
        initialValue: '',
      },
      confirmationMailFooterMessage: {
        label: '申込時メール末尾部の差込文',
        type: 'text',
        rows: 6,
      },
      reminderMailBody: {
        label: 'リマインダーメール本文',
        type: 'text',
        rows: 10,
        initialValue: dedent`
          イベント名: {{EVENT_NAME}}
          開催日: {{LECTURE_DATE}}
          商品種別: {{LECTURE_PRODUCT_TYPE}}
          レクチャー種別: {{LECTURE_TYPE}}
          対象レベル: {{LECTURE_LEVEL}}
          対象年齢: {{LECTURE_AGE}}
          決済金額: {{LECTURE_PRICE}}
        `
      },
      isEnabledCheckIn: {
        label: 'チェックイン機能を有効にする',
        type: 'boolean',
        initialValue: false,
      },
      isEnabledTheDayEntry: {
        label: 'チェックイン画面の当日申込みを有効にする',
        type: 'boolean',
        initialValue: false,
      },
      checkInDescription: {
        label: 'チェックイン画面の説明文(HTML)',
        type: 'richText',
        rows: 10,
        initialValue: '',
      },
      checkInNote: {
        label: 'チェックイン画面の備考(HTML)',
        type: 'richText',
        rows: 10,
        initialValue: '',
      },
      entryIndexPrefix: {
        label: '申込番号接頭辞',
        type: 'string',
      },
      hasCaution: {
        label: '注意事項を表示する',
        type: 'boolean',
        initialValue: true,
      },
      cautionText: {
        label: '注意事項',
        type: 'richText',
        rows: 10,
        initialValue: '',
        validations: {
          required: v => !isEmpty(v) 
        },
        hidden: _ => !_.hasCaution,
      },
      textForQr: {
        label: 'QRコード表示テキスト',
        type: 'string',
        validations: { required:  v => !isEmpty(v), }
      }
    };
  },
  fieldsForInvitedUser: () => {
    return {
      noteForInvitedUser: {
        label: '連絡メモ',
        type: 'richText',
        rows: 10,
        initialValue: '',
      },
    };
  },
};
