import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { get, keyBy, pick, omit, pickBy, isEmpty, orderBy } from 'lodash';
import { useTimeout, useAsync, useLocalStorage, useToggle } from 'react-use';
import retry from 'async-retry';
import qs from 'qs';
import { addDays, addMinutes, differenceInMilliseconds, } from 'date-fns';

import firebase, { functions } from '../../firebase';
import { errorMessages as userErrorMessages } from '../../shared/models/user';
import { generateOrderId } from '../../shared/util';
import NewOrderForm from '../forms/NewOrderForm';
import NewOrderConfirmForm from '../forms/NewOrderConfirmForm';
import TenantUserPage from '../hocs/TenantUserPage';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import useAvailableReferrerKey from '../hooks/useAvailableReferrerKey';
import useAvailableQrUrlId from '../hooks/useAvailableQrUrlId';

const storageRef = firebase.storage().ref();
const auth = firebase.auth();
const db = firebase.firestore();
const productsRef = db.collection('products');
const referrersRef = db.collectionGroup('referrers');
const agentsRef = db.collection('agents');
const createOrder = functions.httpsCallable('createOrder');
const getProductsWithInventory = functions.httpsCallable('getProductsWithInventory');
const getAgentProductPublicSettings = functions.httpsCallable('getAgentProductPublicSettings');
const updateInventoriesByOrder = functions.httpsCallable('updateInventoriesByOrder');
const createUserAndSendEmailVerification = functions.httpsCallable('createUserAndSendEmailVerification');

function NewOrder(props) {
  const {
    history,
    user,
    toggleLoginForm,
    match: {
      params: { tenantPath },
    },
  } = props;
  const shouldSendEvents = window.dataLayer != null && !['admin', 'staff'].includes(get(user, 'role'));
  const cartSettingsRef = db.collection('settings').doc([tenantPath, 'cart'].join('__'));
  const queryParams = useQueryParams();
  const {
    productId,
    productTypeId,
    orderItems,
    wholesale,
    agentId: wholesaleAgentId,
    agentShopId: wholesaleAgentShopId,
  } = queryParams;
  const startAt = new Date(parseInt(queryParams.n, 10));
  const [isWholesaleTimeout] = useTimeout(differenceInMilliseconds(addMinutes(startAt, 45), new Date()));
  const isPartsOrder = !!orderItems;
  const availableReferrerKey = useAvailableReferrerKey();
  const isWholesale = wholesale === '1';
  const isForAgent = isWholesale || !isEmpty(availableReferrerKey);
  const isWish = queryParams.wish === '1';
  const [referrer] = useCollectionSubscriptionInTenant(
    availableReferrerKey && referrersRef.where('key', '==', availableReferrerKey).limit(1),
    [availableReferrerKey]
  );
  const wholesaleAgent = useDocumentSubscription(isWholesale && agentsRef.doc(wholesaleAgentId), [
    isWholesale,
    wholesaleAgentId,
  ]);
  const wholesaleAgentShop = useDocumentSubscription(
    wholesaleAgent?.ref.collection('agentShops').doc(wholesaleAgentShopId),
    [wholesaleAgent, wholesaleAgentShopId]
  );
  const availableQrUrlId = useAvailableQrUrlId();
  const [referralLogs] = useLocalStorage(['referralLogs', tenantPath].join('__'), []);
  const { value: agentProductPublicSettings = [], loading: isLoadingAgentProductPublicSettings = false } =
    useAsync(async () => {
      if (isForAgent) {
        const { data } = await getAgentProductPublicSettings({
          agentId: wholesaleAgentId,
          referrerKey: availableReferrerKey,
        });
        return data;
      }
    }, [isForAgent, wholesaleAgentId]);
  const agentProductPublicSettingsById = keyBy(agentProductPublicSettings, 'id');
  const isAgentUser = !!wholesaleAgent?.members[user?.id];

  // DEV
  // const [showsConfirm, toggleConfirm] = useToggle(true);
  // const coupon = useDocumentSubscription(db.collection('coupons').doc('H9UCWQRHZC'));
  // const [values, setValues] = useState({ orderItems: [{ productId: productId || null, quantity: 1, normalOrderCount: 1, }], destinationType: 'same', splitType: 'one', });
  // useEffect(() => {
  // setValues({ ...values, coupon });
  // }, [coupon]);
  const [showsConfirm, toggleConfirm] = useToggle();
  const [values, setValues] = useState({
    orderItems: orderItems?.map(({ productId, quantity }) => ({ productId, quantity: parseInt(quantity) })) || [
      { productId: productId || null, quantity: 1 },
    ],
    destinationType: 'same',
    deliveryType: 'fastest',
    contactorType: 'destination',
    splitType: 'one',
  });

  const { value: productsWithInfoById = {} } = useAsync(async () => {
    const { data: products } = await getProductsWithInventory({ tenantId: tenantPath });
    return keyBy(
      products.map((_) => ({
        ..._,
        receivingPlanItems: _.receivingPlanItems.map((_) => ({ ..._, date: new Date(JSON.parse(_.date)) })),
      })),
      'id'
    );
  });
  const products = useCollectionSubscriptionInTenant(productsRef.orderBy('code'));
  const productsById = keyBy(products, 'id');
  const filteredProducts = products
    .map((_) => ({ ..._, ...productsWithInfoById[_.id] }))
    .filter((product) => {
      const agentProductPublicSetting = agentProductPublicSettingsById[product.id];
      return agentProductPublicSetting?.[`isShownFor${isWholesale ? 'Wholesale' : 'Referral'}`] || (!product.isHidden && !agentProductPublicSetting?.[`isHiddenFor${isWholesale ? 'Wholesale' : 'Referral'}`]);
    })
    .filter((_) => _.isOption || (isPartsOrder ? _.isPart : !_.isPart))
    .filter((_) => _.code !== 'HP99')
    .filter((_) => _.normalOrderableQuantity > 0 || _.preOrderableQuantity > 0);
  const sortedProducts = orderBy(filteredProducts, [({ isOption }) => (isOption ? 1 : 0)], ['desc']);
  const cartSettings = useDocumentSubscription(cartSettingsRef);
  const onSubmitNewOrderForm = async (_values) => {
    setValues({ ...values, ..._values });
    toggleConfirm(true);
  };
  const onSubmitConfirm = async (_values) => {
    const {
      stripeTokens,
      normalOrderItems,
      normalOrderAmount,
      normalOrderDiscountAmount,
      normalOrderShipmentFee,
      preOrderItems,
      preOrderAmount,
      preOrderDiscountAmount,
      preOrderShipmentFee,
      coupon,
      otherCoupons,
    } = _values;

    try {
      if (normalOrderItems.length > 0) {
        const { data: updatedOrderItems = [] } = await updateInventoriesByOrder({ tenantId: tenantPath, orderItems: normalOrderItems });
        const outOrderItems = updatedOrderItems.filter((_) => _.isOut);
        if (outOrderItems.length > 0) {
          const outProducts = outOrderItems.map((_) => productsById[_.productId]);
          toast.error(
            <div>
              <div>
                申し訳ございません。
                <br />
                たった今、以下の商品の通常注文分が在庫切れとなりました。
              </div>
              <ul className="mt-2">
                {outProducts.map((_) => (
                  <li>{_.name}</li>
                ))}
              </ul>
              <div className="mt-2">大変お手数をお掛けしますが、最初からやり直していただけますようお願いします。</div>
            </div>,
            { autoClose: 10000 }
          );
          history.push('/products');
          return;
        }
      }

      const userValues = await createOrUpdateUser(_values);

      try {
        await [
          {
            amount: normalOrderAmount,
            discountAmount: normalOrderDiscountAmount,
            shipmentFee: normalOrderShipmentFee,
            orderItems: normalOrderItems,
            stripeToken: stripeTokens.pop(),
            isPreOrder: false,
          },
          {
            amount: preOrderAmount,
            discountAmount: preOrderDiscountAmount,
            shipmentFee: preOrderShipmentFee,
            orderItems: preOrderItems,
            stripeToken: stripeTokens.pop(),
            isPreOrder: true,
          },
        ].reduce(async (x, { amount, discountAmount, shipmentFee, orderItems, stripeToken, isPreOrder }) => {
          await x;

          if (orderItems.length === 0) return;

          const id = await generateOrderId();
          let wishFile = null;
          if(_values.wishFile) {
            wishFile = await (async (file) => {
              const storagePath = `orders/${id}/wishFiles/${new Date().toISOString()}/${file.name}`;
              const fileRef = storageRef.child(storagePath);
              await fileRef.put(file, { contentType: file.type });
              return {
                ...pick(file, ['name', 'type']),
                storagePath,
                url: await fileRef.getDownloadURL(),
              };
            })(_values.wishFile);
          }
          const { data } = await createOrder({
            orderId: id,
            orderValues: {
              tenantId: tenantPath,
              ...omit(_values, ['id', 'password', 'otherCoupons']),
              wishFile,
              deliveryDate: JSON.stringify(_values.deliveryDate),
              amount,
              discountAmount,
              shipmentFee,
              orderItems: orderItems.map((_) => ({
                ..._,
                ...(_.planDate && { planDate: JSON.stringify(_.planDate) }),
              })),
              createdBy: omit(userValues, 'ref'),
              isPreOrder,
              wholesaleAgentShopId,
              referralLogs,
              isWish,
            },
            email: _values.email,
            stripeToken,
            amount,
            coupon,
            otherCoupons: otherCoupons.map(_ => omit(_, ['ref'])),
            referrerUrl: document.referrer,
            isWholesale,
            wholesaleAgentId,
          });
          if (get(data, 'error') != null) throw new Error(data.error.message);
        }, Promise.resolve());

        // NOTE: 遷移、トースト
        toast.success('注文完了しました。ありがとうございます。');
        if (shouldSendEvents) {
          window.dataLayer.push({
            URL: window.location.pathname + window.location.search,
            productId,
            event: 'finish',
          });
        }
        [...normalOrderItems, ...preOrderItems]
          .map((_) => productsById[_.productId])
          .filter((_) => !isEmpty(_?.orderTag))
          .map((product) => {
            const fragment = document.createRange().createContextualFragment(product.orderTag);
            document.body.appendChild(fragment);
          });
        history.push(`/mypage/orders?${qs.stringify({ fromNewOrder: 1, productIds: [...normalOrderItems, ...preOrderItems].map(_ => _.productId), })}`);
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
    } catch (e) {
      toast.error('失敗しました');
      console.error(e);
    }
  };
  const createOrUpdateUser = async (_values) => {
    if (user != null) {
      // NOTE: 更新
      await user.ref.update({
        ...pick(_values, ['phone', 'prefecture', 'nameKana', 'postalCode', 'city', 'address', 'route']),
        ...(!isEmpty(_values.children) && { children: _values.children }),
      });
      return user;
    } else {
      // NOTE: アカウント登録
      try {
        const { email, password, name: displayName, children = [] } = _values;
        const userValues = {
          displayName,
          ...pick(_values, [
            'email',
            'phone',
            'prefecture',
            'route',
            'nameKana',
            'postalCode',
            'city',
            'address',
            'children',
          ]),
          children: children.map((_) => ({ ..._, birthday: JSON.stringify(_.birthday) })),
        };
        const {
          data: { uid },
        } = await retry(
          (_) =>
            createUserAndSendEmailVerification({
              userValues,
              password,
              pathname: encodeURIComponent('/mypage/orders'),
              skipsEmailVerification: true,
            }),
          { maxTimeout: 1000 }
        );
        try {
          await auth.signInWithEmailAndPassword(email, password);
        } catch (e) {
          // NOTE: 最悪ログインは失敗しても問題ないのでスルー
          console.error(e);
        }
        return { uid, ...userValues };
      } catch (e) {
        console.error(e);
        const code = get(e, 'details.code') || e.code;
        const message = userErrorMessages[code] || '登録に失敗しました';
        toast.error(message);
        throw e;
      }
    }
  };

  const onClickBackNewOrderForm = ({ orderItems: _orderItems }) => {
    const queryParams = pickBy({ productId, productTypeId, orderItems: _orderItems, wholesale, agentId: wholesaleAgentId, agentShopId: wholesaleAgentShopId }, (_) => !isEmpty(_));
    const partsPath = `/parts?${qs.stringify(queryParams)}`;
    history.push(partsPath);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showsConfirm]);

  useEffect(() => {
    if (shouldSendEvents) {
      window.dataLayer.push({
        URL: window.location.pathname + window.location.search,
        productId,
        event: showsConfirm ? 'confirm' : 'input',
      });
    }
  }, [showsConfirm, shouldSendEvents]);

  return (
    cartSettings != null && (
      <div className="new-order position-relative">
        {
          isWholesale && isWholesaleTimeout() && queryParams.n ? (
            <div className="d-flex justify-content-center">
              <div className="mt-4 alert alert-danger">
                時間切れになりました。<br />
              </div>
            </div>
          ) : (
            products &&
              (!showsConfirm ? (
                <NewOrderForm
                  products={sortedProducts}
                  productsWithInfoById={productsWithInfoById}
                  agentProductPublicSettings={agentProductPublicSettings}
                  onSubmit={onSubmitNewOrderForm}
                  values={values}
                  cartSettings={cartSettings}
                  user={user}
                  wholesaleAgentShop={wholesaleAgentShop}
                  isWholesale={isWholesale}
                  isWish={isWish}
                  isPartsOrder={isPartsOrder}
                  onClickLogin={toggleLoginForm}
                  {...(!isWholesale && { referrer, qrUrlId: availableQrUrlId })}
                  {...(isPartsOrder ? { onClickBack: onClickBackNewOrderForm, hideAddButton: true } : {})}
                />
              ) : (
                <NewOrderConfirmForm
                  values={values}
                  products={products}
                  agentProductPublicSettings={agentProductPublicSettings}
                  onSubmit={onSubmitConfirm}
                  onClickBack={toggleConfirm}
                  cartSettings={cartSettings}
                  user={user}
                  isWholesale={isWholesale}
                  isWish={isWish}
                  isPartsOrder={isPartsOrder}
                  isAgentUser={isAgentUser}
                />
              ))
          )
        }
      </div>
    )
  );
}

NewOrder.preview = true;

export default TenantUserPage(NewOrder);
