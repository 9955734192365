import {
  getConverter,
  getFirestore,
  collection,
  doc,
  Query,
  QueryConstraint,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  getCollectionData,
  DocumentReference,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { LaterQuestionDocumentData, LaterQuestion } from '../types';
import type { PartiallyPartial } from 'types';

export const laterQuestionConverter = getConverter<LaterQuestionDocumentData>();

export const laterQuestionsRef = () =>
  collection(getFirestore(), 'laterQuestions').withConverter(laterQuestionConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<LaterQuestion> : null;
export const laterQuestionRef = <Id extends string | undefined>(laterQuestionId: Id) =>
  (laterQuestionId ? doc(laterQuestionsRef(), laterQuestionId) : null) as RefOrNull<Id>;

export const newLaterQuestionRef = () => doc(laterQuestionsRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const laterQuestionsQuery = (...queryConstraints: QueryConstraint[]) =>
  query(laterQuestionsRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

type QueryOrNull<T extends string | undefined> = T extends string ? Query<LaterQuestion> : null;
export const tenantLaterQuestionsQuery = <T extends string | undefined>(tenantId?: T): QueryOrNull<T> =>
  (tenantId ? laterQuestionsQuery(where('tenantId', '==', tenantId)) : null) as QueryOrNull<T>;

export const addLaterQuestion = async (data: PartiallyPartial<LaterQuestion, 'id' | 'createdAt'>) =>
  addDoc(laterQuestionsRef(), { createdAt: serverTimestamp(), ...data });

export const updateLaterQuestion = async (laterQuestionId: string, data: UpdateData<LaterQuestion>) =>
  updateDoc(laterQuestionRef(laterQuestionId), data);

export const deleteLaterQuestion = async (laterQuestionId: string) => deleteDoc(laterQuestionRef(laterQuestionId));

export const getTenantLaterQuestionCollection = (tenantId: string) =>
  getCollectionData(tenantLaterQuestionsQuery(tenantId));

export const _useLaterQuestionCollection = useCollectionData;

export const _useLaterQuestionCollectionOnce = useCollectionDataOnce;

export const _useLaterQuestionDocument = useDocumentData;

export const _useLaterQuestionDocumentOnce = useDocumentDataOnce;

export const useLaterQuestionCollection = () => _useLaterQuestionCollection(laterQuestionsQuery());

export const useTenantLaterQuestionCollection = (tenantId?: string) =>
  _useLaterQuestionCollection(tenantLaterQuestionsQuery(tenantId));

export const useLaterQuestionCollectionOnce = () => _useLaterQuestionCollectionOnce(laterQuestionsQuery());

export const useLaterQuestionDocument = (laterQuestionId?: string) =>
  _useLaterQuestionDocument(laterQuestionRef(laterQuestionId));

export const useLaterQuestionDocumentOnce = (laterQuestionId?: string) =>
  _useLaterQuestionDocumentOnce(laterQuestionRef(laterQuestionId));
