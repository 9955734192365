import React, { useState } from 'react';
import { Form } from 'reactstrap';
import { pick, isString, pickBy, isEmpty, mapValues, keyBy } from 'lodash';
import { useToggle, useMap } from 'react-use';
import classnames from 'classnames';
import sanitizeHtml from 'sanitize-html';

import { activateRichTextHtml } from '../../util';
import { computeVisibleQuestionIds, computeVisibleAnswers, } from '../../shared/models/survey';
import AppButton from '../AppButton';
import QuestionForm from '../forms/QuestionForm';
import RichTextContent from '../RichTextContent';

const { entries } = Object;

export default function NewSurveyAnswerForm(props) {
  const { activeIndex, setActiveIndex, steps, translate, values, survey, questions = [] } = props;
  const [hasSubmitted, toggleHasSubmitted] = useToggle(false);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const activeStep = steps[activeIndex];
  const questionsById = keyBy(questions, 'id');
  const relatedQuestions = survey?.questionRows?.map(_ => questionsById[_.questionId] || {}) || [];
  const questionRowsByQuestionId = keyBy(survey?.questionRows, 'questionId');
  const initialAnswers = relatedQuestions.reduce((x, { id, type, isOptional = false }) => {
    const value = values.answers?.[id];
    return {
      ...x,
      [id]: {
        isValid: isOptional || !isEmpty(value),
        value: value ?? { text: '', radio: undefined, select: undefined, checkbox: {}, imageFile: [] }[type],
      },
    };
  }, {});
  const [answers, { setAll: setAnswers, }] = useMap(initialAnswers);
  const visibleQuestionIds = computeVisibleQuestionIds(survey, mapValues(answers, 'value'));
  const visibleQuestions = visibleQuestionIds.map(_ => questionsById[_] || {});
  const activeVisibleQuestions = visibleQuestions.filter(_ => (questionRowsByQuestionId[_.id]?.stepIndex ?? 0) === activeIndex);
  const activeQuestionIds = activeVisibleQuestions.map(_ => _.id);
  const validationErrorMessages = [
    ...entries(pick(answers, activeQuestionIds))
      .filter((_) => !_[1].isValid)
      .map(([questionId]) => {
        const question = questionsById[questionId];
        return `「${question?.description}」が回答されていません`;
      }),
  ].filter((_) => _);
  const onSubmit = async (event) => {
    event.preventDefault();
    toggleHasSubmitted(true);
    if (validationErrorMessages.length > 0) return alert(validationErrorMessages.join('\n'));

    toggleSubmitting(true);
    await props.onSubmit({
      answers: mapValues(answers, 'value'),
    });
  };
  const onClickBack = () => {
    setActiveIndex(activeIndex - 1);
  };
  const onClickNext = (e) => {
    e.preventDefault();
    if (validationErrorMessages.length > 0) return alert(validationErrorMessages.join('\n'));

    setActiveIndex(activeIndex + 1);
  };

  return (
    <Form onSubmit={onSubmit}>
      <section className="container-fluid bg-light-grey p-3">
        <h4 className="h5 p-3 text-center font-weight-bold">{survey.title}</h4>
        {
          survey.description && (
            <div className="bg-light-grey rounded p-3">
              <RichTextContent html={survey.description} />
            </div>
          )
        }
        <h6 className="text-center font-weight-bold p-3">
          {activeStep?.title}
        </h6>
        <div>
          <ul className="stepflow m-0">
            {
              steps.map((step, i) => {
                return (
                  <li className={classnames({ completed: i < activeIndex, active: i === activeIndex, })}>
                    <span className="bubble" />
                    {step.title}
                  </li>
                );
              })
            }
          </ul>
        </div>
      </section>
      <section className="container mt-5">
        <div className="row">
          <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div className="mt-4 d-flex flex-column gap-4">
              {activeVisibleQuestions.map((question) => {
                const { id } = question;
                return (
                  <div key={id}>
                    <QuestionForm
                      answer={answers[id]}
                      {...question}
                      onChange={_ => setAnswers(computeVisibleAnswers(survey, { ...answers, [id]: _, }))}
                      shouldStarts={hasSubmitted}
                    />
                  </div>
                );
              })}
            </div>
            <div className="d-flex mt-5 justify-content-around">
              {
                activeIndex > 0 && (
                  <AppButton size="lg" onClick={onClickBack} >
                    <span className="fas fa-arrow-left mr-1" />
                    {translate('戻る')}
                  </AppButton>
                )
              }
              {
                activeIndex < (steps.length - 1) ? (
                  <AppButton color="primary" size="lg" onClick={onClickNext} >
                    <span className="fas fa-arrow-right mr-1" />
                    {translate('次へ')}
                  </AppButton>
                ) : (
                  <AppButton
                    size="lg"
                    color="primary"
                    className="save flex-fill ml-2"
                    type="submit"
                    onClick={onSubmit}
                    disabled={isSubmitting}
                  >
                    <span
                      className={classnames('fas mr-1', {
                        'fa-arrow-right': !isSubmitting,
                        'fa-spin fa-spinner': isSubmitting,
                      })}
                    />
                    {translate('確認画面')}
                  </AppButton>
                )
              }
            </div>
          </div>
        </div>
      </section>
    </Form>
  );
}
