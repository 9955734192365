import React from 'react';
import { toast } from 'react-toastify';
import { get, groupBy, keyBy, orderBy, isEmpty, sortBy, omit, omitBy, pick, isUndefined } from 'lodash';
import { format as formatDate, startOfDay, addDays, } from 'date-fns';
import { Button } from 'reactstrap';

import { canCreateEvent, canUpdateEvent, canDeleteEvent } from '../../shared/abilities';
import firebase from '../../firebase';
import { prefectures } from '../../shared/config';
import AdminPage from '../hocs/AdminPage';
import EventFormModal from '../modals/EventFormModal';
import useCollectionFetchInTenant from '../hooks/useCollectionFetchInTenant';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QueryBoolean from '../QueryBoolean';
import QuerySelector from '../QuerySelector';
import TenantLink from '../TenantLink';
import useFirebaseUser from '../hooks/useFirebaseUser';

const db = firebase.firestore();
const usersRef = db.collection('users');
const lecturesRef = db.collectionGroup('lectures');
const eventsRef = db.collection('events');
const placesRef = db.collection('places');

export default AdminPage(function AdminEvents(props) {
  const { user } = props;
  const { showsAllEvents: _showsAllEvents = '0', area: areaForFilter } = useQueryParams();
  const showsAllEvents = _showsAllEvents === '1';
  const { firebaseUser } = useFirebaseUser();
  const staffs = useCollectionFetchInTenant(usersRef.where('role', 'in', ['admin', 'staff']));
  const staffsById = keyBy(staffs, 'id');
  const lectures = useCollectionSubscriptionInTenant(
    lecturesRef.orderBy('date').where('date', '>=', startOfDay(addDays(new Date(), -30)))
  );
  const lecturesGroupedByEventId = groupBy(lectures, (_) => _.ref.parent.parent.id);
  const events = useCollectionSubscriptionInTenant(eventsRef);
  const eventsWithMostRecentLectureDate = events.map((event) => {
    const lectures = lecturesGroupedByEventId[event.id] || [];
    const [{ date, frames } = {}] = orderBy(lectures, ['date'], ['asc']);
    const [{ startAt } = {}] = orderBy(frames || [], ['startAt'], ['asc']);
    const mostRecentLectureDate =
      date && startAt ? new Date(`${date.toDate().toDateString()} ${startAt.toDate().toTimeString()}`) : null;

    return { ...event, mostRecentLectureDate };
  });
  const sortedEvents = sortBy(eventsWithMostRecentLectureDate, ({ mostRecentLectureDate }) => mostRecentLectureDate);
  let filteredEvents = showsAllEvents
    ? sortedEvents
    : sortedEvents.filter(({ mostRecentLectureDate }) => mostRecentLectureDate);
  if (!isEmpty(areaForFilter)) {
    filteredEvents = filteredEvents.filter((_) => areaForFilter.includes(get(_, 'prefecture')));
  }
  const places = useCollectionSubscriptionInTenant(placesRef);
  const placesById = keyBy(places, 'id');
  const areaOptions = Object.entries(prefectures).map(([key, label]) => ({ label , value: key }));

  const handleClickCopy = async (eventId) => {
    if (!window.confirm('このイベントをコピーしますか？')) return;
    try {
      const event = filteredEvents.find((_) => _.id === eventId);
      if (!event) return;
      await eventsRef.add({
        ...omit(event, ['id', 'ref', 'createdAt', 'createdBy', 'updatedBy']),
        name: `${event.name} - コピー`,
        lectureIds: [],
        addedBy: omitBy(pick(firebaseUser, ['uid', 'email', 'displayName']), isUndefined),
      });
      toast.success('コピーしました。');
    } catch (e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <div>
      <div className="admin-events container py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>イベント一覧</h4>
        </div>
        <div className='d-flex justify-content-between flex-wrap gap-2 mb-3'>
          <QuerySelector
            paramName='area'
            className='ml-0'
            width={400}
            isMulti
            options={areaOptions}
            label='都道府県で絞込み'
          />
          <div className="d-flex justify-content-end gap-2 flex-wrap text-nowrap align-items-end">
            <QueryBoolean paramName="showsAllEvents" label="全イベント表示" defaultValue={'0'} />
            <Button color="secondary" tag={TenantLink} to="/admin/eventCalendar">
              <i className="far fa-calendar-alt mr-1" />
              イベントカレンダー
            </Button>
            <Button color="secondary" tag={TenantLink} to="/admin/entries">
              <i className="far fa-calendar-alt mr-1" />
              申し込み一覧
            </Button>
            <AddInTenantButton
              itemRef={eventsRef.doc()}
              initialValues={{ picId: user.uid }}
              FormModal={EventFormModal}
              disabled={!canCreateEvent(user)}
              formProps={{ places, staffs, user }}
            />
          </div>
        </div>
        <div className="overflow-auto">
          {filteredEvents.length > 0 ? (
            <table className="table">
              <thead className="thead-light text-center text-nowrap">
                <tr>
                  <th>開催日</th>
                  <th>イベント名</th>
                  <th>担当者</th>
                  <th>開催場所</th>
                  <th>都道府県</th>
                  <th>公開状態</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredEvents.map((event) => {
                  const { id, name, placeId, picId, isPublic, ref, mostRecentLectureDate } = event;
                  const place = placesById[placeId];
                  const pic = staffsById[picId];
                  const beforeDelete = async () => {
                    if ((await ref.collection('lectures').limit(1).get()).docs.length > 0) {
                      toast.error('使用されているため削除できません');
                      return false;
                    }
                  };

                  return (
                    <tr key={id}>
                      <td>{!!mostRecentLectureDate && formatDate(mostRecentLectureDate, 'yyyy/MM/dd')}</td>
                      <td>
                        <TenantLink to={`/admin/events/${id}`}>{name}</TenantLink>
                      </td>
                      <td>{pic && pic.displayName}</td>
                      <td>{place && place.name}</td>
                      <td>{place && prefectures[place.prefecture]}</td>
                      <td>
                        {isPublic ? (
                          <span className="badge badge-success">公開</span>
                        ) : (
                          <span className="badge badge-secondary">非公開</span>
                        )}
                      </td>
                      <td className="text-nowrap text-right">
                        <EditButton
                          itemRef={ref}
                          FormModal={EventFormModal}
                          formProps={{ places, staffs, user }}
                          disabled={!canUpdateEvent(user, event)}
                        />
                        <Button
                          className="ml-2"
                          onClick={() => handleClickCopy(id)}
                          disabled={!canUpdateEvent(user, event)}
                        >
                          <span className="fas fa-copy mr-1" />
                          コピー
                        </Button>
                        <DeleteButton
                          itemRef={ref}
                          className="ml-2"
                          disabled={!canDeleteEvent(user, event)}
                          beforeDelete={beforeDelete}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>イベントは未登録です</div>
          )}
        </div>
      </div>
    </div>
  );
});
