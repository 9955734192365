import { Fragment } from 'react';
import platform from 'platform';
import { sum, isEmpty } from 'lodash';
import { Button, Navbar, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import firebase, { sendPasswordResetEmail, signOut } from '../firebase';
import AppButton from './AppButton';
import ModalButton from './ModalButton';
import TenantLink from './TenantLink';

const db = firebase.firestore();

export default function HeaderNav(props) {
  const {
    admin = false,
    tenant,
    user,
    myAccount,
    match: { params: { tenantPath, }, path },
    location: { search },
    translate = _ => _,
  } = props;
  const rootPath = path.match(/^\/admin/) ? '/admin' : '/';
  const isIE = ['Microsoft Edge', 'IE'].includes(platform.name);
  const showsProfilePromotion =
    !admin && user && path !== '/mypage/profile' && !path.match(/^\/agents/) && isEmpty(user.children);
  const onClickPasswordReset = async () => {
    if (!window.confirm('パスワード変更のためのメールをお送りします。よろしいですか？')) return;

    await sendPasswordResetEmail(myAccount.email);
    toast.success('パスワードリセットのメールをお送りしました。');
  };
  const onClickLogOut = async () => {
    if (!window.confirm('ログアウトしますか？')) return;
    await signOut();
    window.location.reload();
  };
  const onClickFollow = async () => {
    if (!window.confirm(`${tenant.name}の情報を受け取りますか？`)) return;

    await user.ref.update({ tenantIds: firebase.firestore.FieldValue.arrayUnion(tenantPath), });
  };
  const onClickClearReferrer = async () => {
    if (!window.confirm(`リファラをクリアします。よろしいですか？`)) return;

    [
      'qrUrlId',
      'qrUrlIdSavedAt',
      'referrerKey',
      'referrerKeySavedAt',
      'referrers',
      // NOTE: referralLogsは残す
      // 'referralLogs',
    ].map(_ => window.localStorage.removeItem([_, tenantPath].join('__')));
    await db.collection('referredEmails').doc(user.email).delete();
    toast.success('リファラをクリアしました');
  };

  return translate(
    <header
      className="header-nav"
      style={{ marginBottom: 54 + sum([isIE && 42, showsProfilePromotion && 49].filter((_) => _)) }}
    >
      <div className="fixed-top" style={{ boxShadow: '8.485px 8.485px 14px 0 rgb(0 0 0 / 5%)' }}>
        <Navbar color="white" light expand="md" className="justify-content-between py-2">
          <div className="d-flex align-items-center gap-1">
            {
              tenant != null ? (
                <TenantLink to={rootPath} className="navbar-brand d-flex align-items-center">
                  <div className="d-flex align-items-start small">
                    <img src={tenant?.logo?.downloadUrl} style={{ maxHeight: 20, maxWidth: 200, }} />
                  </div>
                  {process.env.REACT_APP_ENV !== 'production' && (
                    <span className="font-weight-bold ml-3 small d-none d-md-block">【テスト環境】</span>
                  )}
                </TenantLink>
              ) : (
                <div className="d-flex align-items-center">
                  <h4 className="m-0">VSM</h4>
                  {process.env.REACT_APP_ENV !== 'production' && (
                    <span className="font-weight-bold ml-3 small d-none d-md-block">【テスト環境】</span>
                  )}
                </div>
              )
            }
            {
              tenantPath != null && user != null && !user.tenantIds?.includes(tenantPath) && (
                <Button color="link" onClick={onClickFollow}>
                  <span className="fas fa-bell text-primary" />
                </Button>
              )
            }
          </div>
          <Nav navbar>
            {user ? (
              <UncontrolledDropdown tag="div">
                <DropdownToggle color="grey" className="text-white bg-grey rounded-circle" style={{ width: 38 }}>
                  <span className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu right>
                  {['admin', 'staff'].includes(user.role) && (
                    <Fragment>
                      <DropdownItem tag={TenantLink} to="/admin" tenantId={user.tenantId}>
                        管理画面
                      </DropdownItem>
                      <DropdownItem tag={TenantLink} to="/admin/mypage" tenantId={user.tenantId}>
                        管理マイページ
                      </DropdownItem>
                      <DropdownItem onClick={onClickClearReferrer}>リファラ クリア</DropdownItem>
                    </Fragment>
                  )}
                  <DropdownItem tag={Link} to="/mypage/profile">
                    プロフィール
                  </DropdownItem>
                  <DropdownItem onClick={onClickPasswordReset}>パスワード変更</DropdownItem>
                  <DropdownItem tag={Link} to="/mypage/entries">
                    イベント申込み履歴
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/mypage/orders">
                    注文履歴
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/mypage/contentOrders">
                    コンテンツ注文履歴
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/mypage/troubleInquiries">
                    不具合・組立お問合せ履歴
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/mypage/inquiries">
                    お問合せ履歴
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/mypage/methodInquiries">
                    乗り方・使い方お問合せ履歴
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/mypage/surveyAnswers">
                    アンケート回答履歴
                  </DropdownItem>
                  <DropdownItem onClick={onClickLogOut}>ログアウト</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <NavItem>
                <TenantLink
                  to="/login"
                  className="nav-link"
                >
                  ログイン
                </TenantLink>
              </NavItem>
            )}
          </Nav>
        </Navbar>
        {isIE && (
          <div className="alert alert-danger py-2 mb-0">
            お使いのブラウザは推奨環境ではありません。
            <a href="https://www.google.com/intl/ja_jp/chrome/" target="_blank">
              Google Chrome
            </a>
            のご利用をおすすめいたします。
          </div>
        )}
        {showsProfilePromotion && (
          <div className="alert alert-secondary text-dark py-2 mb-0">
            <span className="fas fa-child mr-1" />
            お子様に合わせた乗り方サポート
            <ModalButton
              label="詳しく"
              title="お子様に合わせた乗り方サポート"
              outline
              className="ml-3"
              color="primary"
              size="sm"
              content={(_) => {
                return (
                  <div>
                    <div>
                      お子様に合わせた乗り方サポートをしています。
                      <br />
                      お子様について教えてください。
                    </div>
                    <div className="mt-3 d-flex justify-content-center">
                      <AppButton outline color="primary" tag={TenantLink} to={`/mypage/profile`}>
                        編集する
                      </AppButton>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>
    </header>
  );
}
