import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  DocumentReference,
  getBytes,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { ContentDocumentData, Content } from '../types';
import type { PartiallyPartial } from 'types';

export const contentConverter = getConverter<ContentDocumentData>();

export const contentsRef = () => collection(getFirestore(), 'contents').withConverter(contentConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<Content> : null;
export const contentRef = <Id extends string | undefined>(contentId: Id) =>
  (contentId ? doc(contentsRef(), contentId) : null) as RefOrNull<Id>;

export const newContentRef = () => doc(contentsRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const contentsQuery = (...queryConstraints: QueryConstraint[]) =>
  query(contentsRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantContentsQuery = (tenantId: string | undefined, ...queryConstraints: QueryConstraint[]) =>
  tenantId ? contentsQuery(where('tenantId', '==', tenantId), ...queryConstraints) : null;

export const addContent = async (data: PartiallyPartial<Content, 'id' | 'createdAt'>) =>
  addDoc(contentsRef(), { createdAt: serverTimestamp(), ...data });

export const updateContent = async (contentId: string, data: UpdateData<Content>) =>
  updateDoc(contentRef(contentId), data);

export const deleteContent = async (contentId: string) => deleteDoc(contentRef(contentId));

export const uploadContentFile = async (contentId: string, file: File, fileName: string = 'file') => {
  const path = `contents/${contentId}/${fileName}`;
  const { type } = file;
  const ref = storageRef(path);
  // NOTE: キャッシュ期限3h
  const metadata = { cacheControl: 'public,max-age=10800', contentType: type };
  await uploadBytes(ref, file, metadata);
  const url = await getDownloadURL(ref);

  return { path, url, type };
};

export const copyContentFile = async (dstContentId: string, { path, type }: { path: string; type: string }) => {
  const srcRef = storageRef(path);
  const data = await getBytes(srcRef);

  const [fileName] = path.split('/').reverse();
  const dstPath = `contents/${dstContentId}/${fileName}`;
  const metadata = { cacheControl: 'public,max-age=10800', contentType: type };
  const dstRef = storageRef(dstPath);
  await uploadBytes(dstRef, data, metadata);
  const url = await getDownloadURL(dstRef);

  return { path: dstPath, url, type };
};

export const deleteContentFile = async (path: string) => deleteObject(storageRef(path));

export const useContentCollection = useCollectionData;

export const useContentCollectionOnce = useCollectionDataOnce;

export const useContentDocument = useDocumentData;

export const useContentDocumentOnce = useDocumentDataOnce;
