import React from 'react';
import { sortBy, keyBy } from 'lodash';
import ellipsis from 'text-ellipsis';
import { toast } from 'react-toastify';

import firebase, { functions } from '../../firebase';
import { canUpdateUserTagRules } from '../../shared/abilities';
import AdminPage from '../hocs/AdminPage';
import UserTagRuleFormModal from '../modals/UserTagRuleFormModal';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import AddButton from '../AddButton';
import ProgressButton from '../ProgressButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { ruleTypes, operators } from '../../shared/models/userTagRule';

const db = firebase.firestore();
const applyUserTagRulesToAllUsers = functions.httpsCallable('applyUserTagRulesToAllUsers', { timeout: 550000 });
const questionsRef = db.collection('questions');
const userTagRulesRef = db.collection('userTagRules');

export default AdminPage(function AdminUserTagRules(props) {
  const { user, tenant, } = props;
  const userTags = useCollectionSubscriptionInTenant(db.collection('userTags'));
  const userTagsById = keyBy(userTags, 'id');
  const questions = useCollectionSubscriptionInTenant(questionsRef);
  const questionsById = keyBy(questions, 'id');
  const userTagRules = sortBy(useCollectionSubscriptionInTenant(userTagRulesRef), _ => _.createdAt.toDate());
  const onClickApplyAllUsers = async () => {
    if(!window.confirm('過去データに適用します。よろしいですか？'));

    try {
      await applyUserTagRulesToAllUsers({ tenantId: tenant.id, });
      toast.success('過去データに適用しました');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <div>
      <div className="admin-question-sets container-fluid py-5 position-relative">
        <div className="d-flex justify-content-center mb-3">
          <h4>ユーザータグルール一覧</h4>
        </div>
        <div className="d-flex justify-content-end gap-1 mb-3">
          <ProgressButton process={onClickApplyAllUsers}>
            過去データに適用する
          </ProgressButton>
          <AddButton
            itemRef={userTagRulesRef.doc()}
            FormModal={UserTagRuleFormModal}
            disabled={!canUpdateUserTagRules(user)}
            formProps={{ userTags }}
          />
        </div>
        <div>
          {userTagRules.length > 0 ? (
            <table className="table">
              <thead className="thead-light text-center">
                <tr>
                  <th style={{ minWidth: 200 }}>ルール種別</th>
                  <th style={{ minWidth: 200 }}>ルール名</th>
                  <th style={{ minWidth: 300 }}>条件</th>
                  <th style={{ minWidth: 200 }}>追加ユーザータグ</th>
                  <th style={{ minWidth: 200 }}>除外ユーザータグ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {userTagRules.map((userTagRule) => {
                  const { id, ref, name, ruleType, rows, userTagIds, userTagIdsToRemove } = userTagRule;
                  return (
                    <tr key={id}>
                      <td>{ruleTypes[ruleType]?.label}</td>
                      <td>{name}</td>
                      <td>
                        <ul className="m-0">
                          {rows.map((rule, i) => {
                            const { subject, operator, value } = rule;
                            const displaySubject = {
                              surveyAnswer: () => {
                                return questionsById[subject]?.name;
                              },
                            }[ruleType]();
                            const displayValue = {
                              surveyAnswer: () => {
                                const question = questionsById[subject];
                                return {
                                  text: (_) => value,
                                  radio: (_) => value.join(', '),
                                  select: (_) => value.join(', '),
                                  checkbox: (_) => value.join(', '),
                                }[question?.type]?.();
                              },
                            }[ruleType]();
                            return (
                              <li key={i} className="small">
                                <span>「{ellipsis(displaySubject, 15)}」</span>
                                <span className="ml-1">"{displayValue}"</span>
                                <span className="ml-1">{operators[operator].label}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </td>
                      <td>
                        <ul ref={ref}>
                          {(userTagIds || []).map((userTagId) => {
                            const userTag = userTagsById[userTagId];
                            return (
                              userTag != null && (
                                <li key={userTag.id} className="small">
                                  {userTag.name}
                                </li>
                              )
                            );
                          })}
                        </ul>
                      </td>
                      <td>
                        <ul ref={ref}>
                          {(userTagIdsToRemove || []).map((userTagId) => {
                            const userTag = userTagsById[userTagId];
                            return (
                              userTag != null && (
                                <li key={userTag.id} className="small">
                                  {userTag.name}
                                </li>
                              )
                            );
                          })}
                        </ul>
                      </td>
                      <td className="text-nowrap text-right">
                        <EditButton
                          itemRef={ref}
                          className="ml-2"
                          FormModal={UserTagRuleFormModal}
                          disabled={!canUpdateUserTagRules(user)}
                          formProps={{ userTags }}
                        />
                        <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateUserTagRules(user)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div>ユーザータグルールは未登録です</div>
          )}
        </div>
      </div>
    </div>
  );
});
