import {
  getConverter,
  getFirestore,
  collection,
  doc,
  QueryConstraint,
  query,
  where,
  orderBy,
  addDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  UpdateData,
  DocumentReference,
} from '../firebase';
import { useCollectionData } from '../components/hooks/useCollectionData';
import { useCollectionDataOnce } from '../components/hooks/useCollectionDataOnce';
import { useDocumentData } from '../components/hooks/useDocumentData';
import { useDocumentDataOnce } from '../components/hooks/useDocumentDataOnce';
import type { UserTagDocumentData, UserTag } from '../types';
import type { PartiallyPartial } from 'types';

export const userTagConverter = getConverter<UserTagDocumentData>();

export const userTagsRef = () => collection(getFirestore(), 'userTags').withConverter(userTagConverter);

type RefOrNull<Id extends string | undefined> = Id extends string ? DocumentReference<UserTag> : null;
export const userTagRef = <Id extends string | undefined>(userTagId: Id) =>
  (userTagId ? doc(userTagsRef(), userTagId) : null) as RefOrNull<Id>;

export const newUserTagRef = () => doc(userTagsRef());

export const defaultQueryConstraints = [orderBy('createdAt', 'asc')];

export const tenantQueryConstraints = (tenantId: string) => [where('tenantId', '==', tenantId)];

export const userTagsQuery = (...queryConstraints: QueryConstraint[]) =>
  query(userTagsRef(), ...(queryConstraints.length === 0 ? defaultQueryConstraints : queryConstraints));

export const tenantUserTagsQuery = (tenantId: string | undefined) =>
  tenantId ? userTagsQuery(...tenantQueryConstraints(tenantId)) : null;

export const addUserTag = async (data: PartiallyPartial<UserTag, 'id' | 'createdAt'>) =>
  addDoc(userTagsRef(), { createdAt: serverTimestamp(), ...data });

export const updateUserTag = async (userTagId: string, data: UpdateData<UserTag>) =>
  updateDoc(userTagRef(userTagId), data);

export const deleteUserTag = async (userTagId: string) => deleteDoc(userTagRef(userTagId));

export const useUserTagCollection = useCollectionData;

export const useUserTagCollectionOnce = useCollectionDataOnce;

export const useUserTagDocument = useDocumentData;

export const useUserTagDocumentOnce = useDocumentDataOnce;
