import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import copy from 'copy-to-clipboard';
import { sortBy, keyBy, } from 'lodash';

import { canUpdateAgent, } from '../../shared/abilities';
import firebase from '../../firebase';
import { beforeDelete, } from '../../util';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionSubscriptionInTenant from '../hooks/useCollectionSubscriptionInTenant';
import useQueryParams from '../hooks/useQueryParams';
import AdminPage from '../hocs/AdminPage';
import AdminAgentShopHeader from '../AdminAgentShopHeader';
import ModelFormModal from '../modals/ModelFormModal';
import AddInTenantButton from '../AddInTenantButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QueryBoolean from '../QueryBoolean';
import { fields } from '../../shared/models/referrer';
import TenantLink from '../TenantLink';

const db = firebase.firestore();
const agentsRef = db.collection('agents');
const ordersRef = db.collection('orders');
const qrBottomDisplaysRef = db.collection('qrBottomDisplays');

export default AdminPage(function AdminAgent (props) {
  const { tenant, user, match: { params: { agentId, agentShopId, } }, addBreadNavValues } = props;
  const {
    showsHidden,
  } = useQueryParams();
  const agentRef = agentsRef.doc(agentId);
  const agentShopRef = agentRef.collection('agentShops').doc(agentShopId);
  const agent = useDocumentSubscription(agentRef, [agentId]);
  const agentShop = useDocumentSubscription(agentShopRef, [agentId, agentShopId]);
  const referrers = useCollectionSubscription(agentShopRef.collection('referrers').orderBy('createdAt'), [agentId, agentShopId]);
  const qrBottomDisplays = sortBy(useCollectionSubscriptionInTenant(qrBottomDisplaysRef), _ => _.createdAt.toDate());
  const qrBottomDisplaysById = keyBy(qrBottomDisplays, 'id');
  const isReachedLimit = referrers.filter(_ => !_.isHidden).length >= (agent?.maxReferrersCount ?? 5);

  let filteredReferrers = referrers;
  if (showsHidden !== '1') {
    filteredReferrers = filteredReferrers.filter(_ => !_.isHidden);
  }

  useEffect(() => {
    addBreadNavValues({ agent, agentShop, })
  }, [agent, agentShop]);

  return agentShop != null && (
    <div>
      <div className="admin-agent-shop container py-5 position-relative">
        <AdminAgentShopHeader activeTab="referrers" user={user} agent={agent} agentShop={agentShop} />
        <div className="bg-white p-4">
          <div className="row">
            <div className="col-12">
              {isReachedLimit && <div className="alert alert-warning">リファラ数の上限に達しています</div>}
              <div className="d-flex align-items-end justify-content-end mb-3 gap-1">
                <QueryBoolean paramName='showsHidden' label='非表示も表示' defaultValue='0' />
                <AddInTenantButton itemRef={agentShopRef.collection('referrers').doc(uuid())} processValues={_ => ({ ..._, key: _.id })} FormModal={ModelFormModal} formProps={{ title: 'リファラ追加', fields: fields({ qrBottomDisplays }), }} disabled={!canUpdateAgent(user) || isReachedLimit} />
              </div>
              {
                referrers.length > 0 ? (
                  <table className="table">
                    <thead className="thead-light text-center">
                      <tr>
                        <th>名称</th>
                        <th>QR下部表記</th>
                        <th>QRコード表示テキスト</th>
                        <th>key</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (filteredReferrers || []).map((referrer) => {
                          const { id, ref, name, qrBottomDisplayId, textForQr, key, isHidden = false, } = referrer;
                          const onClickCopy = () => {
                            copy(key);
                            toast.success('クリップボードにコピーしました');
                          };

                          return (
                            <tr key={id} style={{ background: isHidden ? 'lightgray' : '' }}>
                              <td>
                                <TenantLink to={`/admin/agents/${agentId}/agentShops/${agentShopId}/referrers/${id}`}>
                                  {name}
                                </TenantLink>
                              </td>
                              <td>
                                {qrBottomDisplaysById[qrBottomDisplayId]?.name}
                              </td>
                              <td>
                                {textForQr}
                              </td>
                              <td>
                                {key}
                                <Button className="ml-1" size="sm" color="link" onClick={onClickCopy}>
                                  <span className="fas fa-copy" />
                                </Button>
                              </td>
                              <td className="text-right">
                                <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: 'リファラ編集', fields: fields({ qrBottomDisplays }), }} disabled={!canUpdateAgent(user)} />
                                <DeleteButton itemRef={ref} className="ml-2" disabled={!canUpdateAgent(user)} beforeDelete={beforeDelete([ordersRef.where('tenantId', '==', tenant.id).where('referrerKey', '==', key)])} />
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                ) : (
                  <div>
                    リファラは未登録です
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

