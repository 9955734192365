import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import TenantUserPage from '../hocs/TenantUserPage';

function Contacts(props) {
  const history = useHistory();

  useEffect(() => {
    history.push('/mypage/orders');
  }, []);
  return null;
}

export default TenantUserPage(Contacts);
